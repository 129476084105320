/* eslint-disable */
import {
  RACSelect,
  Typography,
  Grid,
  CardContent,
  Card,
  RACTextbox,
  RACDatePicker,
  CircularProgress,
  RACToggle,
  List,
  ListItem,
  RACTooltip,
} from "@rentacenter/racstrap";
import moment from "moment";
import { MultiSelect } from "react-multi-select-component";
import React, { useContext, useEffect, useState } from "react";
import { PromoSchedulerContext } from "./PromoSchedulerContext";
import { PricingReportStyle } from "../Pricing/Styles/PricingReport";
import { getHierarchyType } from "../shared/PromoDropDownDetails";
import PromoSchedulerAssociatedStoresGrid from "./PromoSchedulerAssociatedStoresGrid";
import { ProgressBar } from "../Pricing/Styles/ProgressStyles";
import { globalStyles } from "../Pricing/Styles/globalstyles";
import { ReactComponent as BlueInfoIcon } from "./../../assets/images/blue-info-icon.svg";

export default function PromoSchedulerForm() {
  const {
    hasSelectAll,
    setHasSelectAll,
    franchiseeuser,
    formLoader,
    setFormLoader,
    filterLoaderFlag,
    orgArray,
    setHiererchyArrayForm,
    setOrgArray,
    noAssStoresFound,
    setAssignStoreObj,
    assignStoreObj,
    setEmptyPostObj,
    TRTOD,
    setTRTOD,
    INITD,
    setINITD,
    NUMPAYMENT,
    setNUMPAYMENT,
    PERCENTOFF,
    setPERCENTOFF,
    USEFREETIME,
    setUSEFREETIME,
    MAXWEEKS,
    setMAXWEEKS,
    MAXDAYS,
    setMAXDAYS,
    MAXDAYSMONTHLY,
    setMAXDAYSMONTHLY,
    MAXDAYSOTHER,
    setMAXDAYSOTHER,
    CLUBAMOUNT,
    setCLUBAMOUNT,
    postObj,
    setPostObj,
    setScheduleType,
    setNewScheduleFooter,
    setUpdateFooter,
    setSubmissionFooter,
    setPsActionPage,
    assStoreGrid,
    setAssStoreGrid,
    setLandingPage,
    promotype,
    promoGroup,
    organization,
    typeDropdown,
    setMainLoader,
    setTypeFlag,
    company,
    setSomethingWentWrongFilter,
    exposeFlag,
    promoRateFlag,
    dueDateFlag,
    maxWeeksAllowedFlag,
    freeTimeWeeksAllowedFlag,
    freeDayFlag,
    referralPromoFlag,
    manageFields,
    promoGroupFlag,
    resnForPromoFlag,
    promoTypeFlag,
    promoNameFlag,
    promoCodeFlag,
    startDateFlag,
    promoDescFlag,
    promoDetailsTxtFlag,
    bedCrumbs,
    headings,
    buttonDisabler,
    setButtonDisabler,
    hasSelectAllgetHeirarchyForm,
    setHasSelectAllgetHeirarchyForm,
    setErrorStatement,setErrorMessage
  } = useContext(PromoSchedulerContext);
  const classes: any = ProgressBar();
  let promoRateArray:Array<String>=[
    'PAYNFSTY+',
    'FIXED',
    'POR',
    'AED',
    'FMUFD',
    'FIRST_WK_5',
    'POR_FT',
    'PAYNFSTY',
    'FAF',
    'PAYXTOY'
];
let maxDaysAllowedArray:Array<String>= [
  'PAYNFSTY+',
  'DDISC',
  'PAYNFSTY',
  'PAYNMATCHN'
];
  const Class = globalStyles();
  const [hierarchyLoader, setHierarchyLoader] = useState(true);
  const [formHierarchyValueSelected, setFormHierarchyValueSelected] =
    useState<any>([]);
  const [formOrganizationSelected, setFormOrganizationSelected] = useState<any>(
    []
  );
  const numberOnlyRegex = /^[0-9\b]+$/;
  const numberAndDecimalOnlyRegex = /^[0-9.]*$/;
  const decimalNumbersHandleZeroToOneRegex = /^(0(\.0+)?|0\.|0\.\d*|0\.[0-9]*[1-9][0-9]*|1(\.0+)?)$/;
  const [hierarchyValueField, setHierarchyValueField] = useState(false);
  const [typeObj, setTypeObj] = useState<any>([
    {
      label: "Select",
      value: "",
    },
  ]);
  const pricing = PricingReportStyle();
  const hanleKeyBoardEvents =(e: any)=>{
    e.preventDefault(); // Prevent keyboard input
  }
  const handleType = async (e: any, key: any) => {
    setHierarchyValueField(true);
    setHiererchyArrayForm([]);
    const response = await getHierarchyType(
      postObj.assignStores.companyCode,
      e.target.value
    );
    console.log("HeirarcyType respone", response?.hierarchyType);
    if (response?.status === 200) {
      setHierarchyLoader(false);
      switch (true) {
        case e.target.value == "STATE": {
          setTypeFlag("State");
          setTypeObj(response?.hierarchyType);
          break;
        }
        case e.target.value == "STORE": {
          setTypeFlag("STORE");
          setTypeObj(response?.hierarchyType);
          break;
        }
        case e.target.value == "COUNTRY": {
          setTypeFlag("COUNTRY");
          setTypeObj(response?.hierarchyType);
          break;
        }
        case e.target.value == "LOB": {
          setTypeFlag("LOB");
          setTypeObj(response?.hierarchyType);
          break;
        }
        case e.target.value == "DISTRICT": {
          setTypeFlag("DISTRICT");
          setTypeObj(response?.hierarchyType);
          break;
        }
        case e.target.value == "REGION": {
          setTypeFlag("REGION");
          setTypeObj(response?.hierarchyType);
          break;
        }
      }

      setMainLoader(false);
    } else {
      setHasSelectAllgetHeirarchyForm(false);
      // setHasSelectAll(false)
      setMainLoader(false);
      setHierarchyLoader(false);
      setFormHierarchyValueSelected([]);
      setSomethingWentWrongFilter(true);
      setAssignStoreObj({
        ...assignStoreObj,
        hierarchyType: "",
      });
      setTypeObj([/* { value: "", label: "Select" } */]);
    }
  };
  console.log("post obj in form", postObj);
  console.log("postObj in form", postObj);
  return (
    <>
      {formLoader == true ? (
        <Grid container className={classes.masterLoader}>
          <Grid className={classes.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      <Grid>
        {bedCrumbs == "UPDATE_PROMO" ? (
          <>
            {" "}
            <List className={`${Class.breadcrumb} ${Class.py1}`}>
              <ListItem
                style={{ paddingLeft: "0" }}
                className={`${Class.breadcrumbItemActive} `}
              >
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setEmptyPostObj();
                    setScheduleType("");
                    setNewScheduleFooter(true);
                    setUpdateFooter(false);
                    setSubmissionFooter(false);
                    setPsActionPage(false);
                    setAssStoreGrid(false);
                    setLandingPage(true);
                  }}
                >
                  Promo Scheduler
                </a>
              </ListItem>
              <ListItem
                className={`${Class.breadcrumbItemActive1}  ${Class.breadcrumbItem} ${Class.breadcrumbArrow} `}
              >
                <a>Update Promo Schedule</a>
              </ListItem>
            </List>
          </>
        ) : bedCrumbs == "NEW_SCHEDULE" ? (
          <>
            {" "}
            <List className={`${Class.breadcrumb} ${Class.py1}`}>
              <ListItem
                style={{ paddingLeft: "0" }}
                className={`${Class.breadcrumbItemActive} `}
              >
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setEmptyPostObj();
                    setScheduleType("");
                    setNewScheduleFooter(true);
                    setUpdateFooter(false);
                    setSubmissionFooter(false);
                    setPsActionPage(false);
                    setAssStoreGrid(false);
                    setLandingPage(true);
                  }}
                >
                  Promo Scheduler
                </a>
              </ListItem>
              <ListItem
                className={`${Class.breadcrumbItemActive1}  ${Class.breadcrumbItem} ${Class.breadcrumbArrow} `}
              >
                <a>Create Promo Schedule</a>
              </ListItem>
            </List>
          </>
        ) : bedCrumbs == "CLONE" ? (
          ""
        ) : null}
        <Grid
          container
          style={{
            marginBottom: "100px",
            marginTop: "15px",
            tableLayout: "fixed",
            width: "100%",
          }}
          spacing={1}
        >
          <Grid item md={6}>
            <Typography style={{ marginBottom: "5px" }} variant="h5">
              {headings == "UPDATE_PROMO"
                ? "Update Promo Schedule"
                : headings == "CLONE" || headings == "NEW_SCHEDULENE"
                ? "Create Promo Schedule"
                : null}
            </Typography>
            <Card
              style={{ borderRadius: "18px", tableLayout: "fixed" }}
              className={classes.dropDownStyles}
            >
              <CardContent>
                <Grid style={{ tableLayout: "fixed" }} container spacing={1}>
                  <Grid item md={4} style={{ paddingBottom: "18px" }}>
                    <RACSelect
                      loading={filterLoaderFlag}
                      inputLabel="Promo Group"
                      required={
                        headings == "NEW_SCHEDULENE" || headings == "CLONE"
                          ? true
                          : false
                      }
                      isDisabled={promoGroupFlag}
                      options={promoGroup}
                      defaultValue={
                        postObj.promoGroup ? postObj.promoGroup : ""
                      }
                      onChange={(e) => {
                        setPostObj({
                          ...postObj,
                          ["promoGroup"]: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    className={classes.dropDownStyles}
                    style={{ paddingBottom: "18px" }}
                  >
                    <RACSelect
                      loading={filterLoaderFlag}
                      inputLabel="Promo Type"
                      required={
                        headings == "NEW_SCHEDULENE" || headings == "CLONE"
                          ? true
                          : false
                      }
                      isDisabled={promoTypeFlag}
                      options={promotype}
                      defaultValue={postObj.promoType ? postObj.promoType : ""}
                      onChange={(e) => {
                        setPostObj({
                          ...postObj,
                          ["promoType"]: e.target.value,
                        });
                        manageFields(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item md={4} style={{ paddingBottom: "18px" }}>
                    <RACTextbox
                      required={
                        headings == "NEW_SCHEDULENE" || headings == "CLONE"
                          ? true
                          : false
                      }
                      disabled={promoNameFlag}
                      className={classes.promoTextBoxBlue}
                      value={postObj.promoName ? postObj.promoName : ""}
                      maxlength={40}
                      inputlabel="Promo Name"
                      OnChange={(e) => {
                        setPostObj({
                          ...postObj,
                          ["promoName"]: e.target.value,
                        });
                      }}
                    ></RACTextbox>
                  </Grid>
                  <Grid item md={4} style={{ paddingBottom: "18px" }}>
                    <RACTextbox
                      required={
                        headings == "NEW_SCHEDULENE" || headings == "CLONE"
                          ? true
                          : false
                      }
                      disabled={promoCodeFlag}
                      className={classes.promoTextBoxBlue}
                      value={postObj.promoCode ? postObj.promoCode : ""}
                      maxlength={20}
                      inputlabel="Promo Code"
                      OnChange={(e) => {
                        setPostObj({
                          ...postObj,
                          ["promoCode"]: e.target.value,
                        });
                      }}
                    ></RACTextbox>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    lg={4}
                    sm={2}
                    xs={2}
                    style={{ paddingBottom: "18px" }}
                    className={classes.currencyInputStylePromo}
                  >
                    <RACTextbox
                      required={
                        (headings == "CLONE" || headings == "NEW_SCHEDULENE") &&
                        promoRateArray.includes(postObj.promoType)
                          ? true
                          : false
                      }
                      dollarTextClassName={{ padding: "7px" }}
                      disabled={
                        headings == "UPDATE_PROMO"
                          ? true
                          : postObj.promoType == "PAYNFSTY+" ||
                            postObj.promoType == "PAYNFSTY"
                          ? false
                          : promoRateFlag
                      }
                      className={classes.promoTextBoxBlue}
                      value={
                        postObj.promoConfig?.promoRate != ""
                          ? postObj.promoConfig?.promoRate
                          : ""
                      }
                      isCurrency={true}
                      minlength={10}
                      maxlength={10}
                      inputlabel="Promo Rate"
                      Onblur={(e: any) => {
                        // if (
                        //   postObj.promoType == "FIXED" ||
                        //   postObj.promoType == "POR" ||
                        //   postObj.promoType == "FMUFD" ||
                        //   postObj.promoType == "FIRST_WK_5" ||
                        //   postObj.promoType == "POR_FT"
                        // ) {
                        //   if (e.target.value == 0) {
                        //     setPostObj((pre: any) => ({
                        //       ...pre,
                        //       promoConfig: {
                        //         ...pre.promoConfig,
                        //         promoRate: "",
                        //       },
                        //     }));

                        //     setFormLoader(false);
                        //     setErrorMessage(true);
                        //     setErrorStatement(
                        //       `Promo rate cannot be 0 for ${postObj.promoType} promo type`
                        //     );
                        //   }
                        // }
                        if (
                          postObj.promoType == "FAF" &&
                          Number(e.target.value) > 1
                        ) {
                          setFormLoader(false);
                          setErrorMessage(true);
                          setErrorStatement(
                            "Promo rate cannot be greater than 1 for FAF promo type"
                          );
                        }
                      }}
                      OnChange={(e: any) => {
                        let a = e.target.value;
                        if (a == ".") a = a.replace(".", "0.");
                        a = a.replace(/(\.\d*)\..*$/, "$1"); // Remove any subsequent decimal points after the first one
                        a = a.replace(/[^\d.]+/, ""); // Remove all non-numeric characters except .
                        e.target.value = a.replace(
                          /^[0-9]*(\.)?(\.[0-9]{2})\d+?$/,
                          "$1"
                        );

                        if (
                          numberAndDecimalOnlyRegex.test(e.target.value) ||
                          e.target.value == ""
                        ) {
                          if (
                            postObj.promoType == "FIXED" ||
                            postObj.promoType == "POR" ||
                            postObj.promoType == "FMUFD" ||
                            postObj.promoType == "FIRST_WK_5" ||
                            postObj.promoType == "POR_FT"
                          ) {
                            setPostObj((pre: any) => ({
                              ...pre,
                              promoConfig: {
                                ...pre.promoConfig,
                                promoRate: e.target.value,
                              },
                            }));
                          } else {
                            setPostObj((pre: any) => ({
                              ...pre,
                              promoConfig: {
                                ...pre.promoConfig,
                                promoRate: e.target.value,
                              },
                            }));
                          }
                        }
                      }}
                    ></RACTextbox>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    style={{ paddingBottom: "18px" }}
                    className={
                      bedCrumbs == "UPDATE_PROMO" || dueDateFlag == true
                        ? classes.cusStyleDisableColor
                        : classes.datePickerStyles
                    }
                  >
                    <RACDatePicker
                      required={
                        ((headings == "CLONE" ||
                          headings == "NEW_SCHEDULENE") &&
                          postObj.promoType == "FIXED") ||
                        postObj.promoType == "PAYXTOY"
                          ? true
                          : false
                      }
                      inputProps={{ min: moment().format("YYYY-MM-DD") }}
                      classes={classes.datePickerStyles}
                      disabled={
                        headings == "UPDATE_PROMO"
                          ? true
                          : postObj.promoType == "PAYNFSTY+"
                          ? true
                          : dueDateFlag
                      }
                      value={
                        postObj.promoConfig?.dueDate
                          ? postObj.promoConfig?.dueDate
                          : ""
                      }
                      onChange={(e: any) => {
                        const date1 = moment(e, "YYYY-MM-DD");
                        const date2 = moment(postObj.startDate, "YYYY-MM-DD");
                        if (date2.isAfter(date1)) {
                          setPostObj((pre: any) => ({
                            ...pre,
                            startDate: e,
                            promoConfig: {
                              ...pre.promoConfig,
                              dueDate: "",
                            },
                          }));
                        } else {
                          setPostObj((pre: any) => ({
                            ...pre,
                            promoConfig: {
                              ...pre.promoConfig,
                              dueDate: e,
                            },
                          }));
                        }
                        setPostObj((pre: any) => ({
                          ...pre,
                          promoConfig: {
                            ...pre.promoConfig,
                            dueDate: e,
                          },
                        }));
                      }}
                      label="Due Date"
                    />
                  </Grid>

                  {postObj.promoType == "PAYNFSTY+" ||
                  postObj.promoType == "PAYXTOY" ? (
                    <Grid item md={4} style={{ paddingBottom: "18px" }}>
                      <RACTextbox
                        required={
                          headings == "CLONE" || headings == "NEW_SCHEDULENE"
                            ? true
                            : false
                        }
                        className={classes.promoTextBoxBlue}
                        disabled={headings == "UPDATE_PROMO" ? true : false}
                        value={
                          CLUBAMOUNT.promoValue ? CLUBAMOUNT.promoValue : ""
                        }
                        minlength={10}
                        maxlength={10}
                        inputlabel="Club Amount"
                        OnChange={(e) => {
                          if (
                            numberAndDecimalOnlyRegex.test(e.target.value) ||
                            e.target.value == ""
                          ) {
                            setCLUBAMOUNT({
                              ...CLUBAMOUNT,
                              promoKey: "DEF_CLUB_PAYMENT",
                              promoValue: e.target.value,
                            });
                          }
                        }}
                      ></RACTextbox>
                    </Grid>
                  ) : (
                    (CLUBAMOUNT.promoValue = "")
                  )}
                  {postObj.promoType == "PIFEPOMNM" ||
                  postObj.promoType == "PIFEPO" ? (
                    <Grid item md={4} style={{ paddingBottom: "18px" }}>
                      <RACTextbox
                        required={
                          headings == "CLONE" || headings == "NEW_SCHEDULENE"
                            ? true
                            : false
                        }
                        className={classes.promoTextBoxBlue}
                        disabled={headings == "UPDATE_PROMO" ? true : false}
                        value={TRTOD.promoValue ? TRTOD.promoValue : ""}
                        minlength={10}
                        maxlength={10}
                        inputlabel="Total RTO discount"
                        OnChange={(e) => {
                          if (
                            numberAndDecimalOnlyRegex.test(e.target.value) ||
                            e.target.value == ""
                          ) {
                            setTRTOD({
                              ...TRTOD,
                              promoKey: "TRTOD",
                              promoValue: e.target.value,
                            });
                          }
                        }}
                      ></RACTextbox>
                    </Grid>
                  ) : (
                    (TRTOD.promoValue = "")
                  )}
                  {postObj.promoType == "PIFEPOIPF" ||
                  postObj.promoType == "GCRP" ||
                  postObj.promoType == "PIFEPOMNM" ||
                  postObj.promoType == "PIFEPOIP" ? (
                    <Grid item md={4} style={{ paddingBottom: "18px" }}>
                      <RACTextbox
                        required={
                          headings == "CLONE" || headings == "NEW_SCHEDULENE"
                            ? true
                            : false
                        }
                        className={classes.promoTextBoxBlue}
                        disabled={headings == "UPDATE_PROMO" ? true : false}
                        value={INITD.promoValue ? INITD.promoValue : ""}
                        minlength={10}
                        maxlength={10}
                        inputlabel="Initial Discount"
                        OnChange={(e) => {
                          if (
                            numberAndDecimalOnlyRegex.test(e.target.value) ||
                            e.target.value == ""
                          ) {
                            setINITD({
                              ...INITD,
                              promoKey: "INITD",
                              promoValue: e.target.value,
                            });
                          }
                        }}
                      ></RACTextbox>
                    </Grid>
                  ) : (
                    (INITD.promoValue = "")
                  )}
                  {postObj.promoType == "PAY4GET1" ||
                  postObj.promoType == "PAYXSKIPY" ? (
                    <Grid item md={4} style={{ paddingBottom: "18px" }}>
                      <RACTextbox
                        required={
                          headings == "CLONE" || headings == "NEW_SCHEDULENE"
                            ? true
                            : false
                        }
                        className={classes.promoTextBoxBlue}
                        disabled={headings == "UPDATE_PROMO" ? true : false}
                        value={
                          NUMPAYMENT.promoValue ? NUMPAYMENT.promoValue : ""
                        }
                        minlength={10}
                        maxlength={10}
                        inputlabel="# of payment"
                        OnChange={(e) => {
                          if (
                            numberOnlyRegex.test(e.target.value) ||
                            e.target.value == ""
                          ) {
                            setNUMPAYMENT({
                              ...NUMPAYMENT,
                              promoKey: "NUM_PAYMENT",
                              promoValue: e.target.value,
                            });
                          }
                        }}
                      ></RACTextbox>
                    </Grid>
                  ) : (
                    (NUMPAYMENT.promoValue = "")
                  )}

                  {postObj.promoType == "HLFOFF_NTR" ||
                  postObj.promoType == "PAYXSKIPY" ||
                  postObj.promoType == "PAY4GET1" ||
                  postObj.promoType == "HLFOFF_INT" ? (
                    <Grid item md={4} style={{ paddingBottom: "18px" }}>
                      <RACTextbox
                        required={
                          headings == "CLONE" || headings == "NEW_SCHEDULENE"
                            ? true
                            : false
                        }
                        className={classes.promoTextBoxBlue}
                        disabled={headings == "UPDATE_PROMO" ? true : false}
                        value={
                          PERCENTOFF.promoValue ? PERCENTOFF.promoValue : ""
                        }
                        maxlength={10}
                        inputlabel="Percentage Off"
                        OnChange={(e) => {
                          if (
                            decimalNumbersHandleZeroToOneRegex.test(
                              e.target.value
                            ) ||
                            e.target.value == ""
                          ) {
                            setPERCENTOFF({
                              ...PERCENTOFF,
                              promoKey: "PERCENT_OFF",
                              promoValue: e.target.value,
                            });
                          }
                        }}
                        infoIcon={true}
                        infoTitle={`Enter value within 0 to 1, ex. 0.25 for 25%`}
                        inputLabelClassname={classes.mr10px}
                      ></RACTextbox>
                      {/* <RACTooltip
                        placement={"right"}
                        className={classes.tooltipcss}
                        classes={{
                          tooltip: classes.toolTipStyle1,
                          arrow: classes.arrowStyle,
                        }}
                        title={`Enter value within 0 to 1, ex. 0.25 for 25%`}
                      >
                        <BlueInfoIcon
                          style={{
                            height: "15px",
                            marginLeft: "0px",
                            marginTop: "6px",
                          }}
                        />
                      </RACTooltip> */}
                    </Grid>
                  ) : (
                    (PERCENTOFF.promoValue = "")
                  )}
                  {postObj.promoType == "HLFOFF_INT" ? (
                    <Grid item md={4} style={{ paddingBottom: "18px" }}>
                      <RACTextbox
                        required={
                          headings == "CLONE" || headings == "NEW_SCHEDULENE"
                            ? true
                            : false
                        }
                        className={classes.promoTextBoxBlue}
                        disabled={headings == "UPDATE_PROMO" ? true : false}
                        value={
                          USEFREETIME.promoValue ? USEFREETIME.promoValue : ""
                        }
                        minlength={10}
                        maxlength={10}
                        inputlabel="Use Free Time"
                        OnChange={(e) => {
                          if (
                            numberOnlyRegex.test(e.target.value) ||
                            e.target.value == ""
                          ) {
                            setUSEFREETIME({
                              ...USEFREETIME,
                              promoKey: "USE_FREE_TIME",
                              promoValue: e.target.value,
                            });
                          }
                        }}
                      ></RACTextbox>
                    </Grid>
                  ) : (
                    (USEFREETIME.promoValue = "")
                  )}

                  {postObj.promoType == "HLFOFF_INT" ? (
                    <Grid item md={4} style={{ paddingBottom: "18px" }}>
                      <RACTextbox
                        required={
                          headings == "CLONE" || headings == "NEW_SCHEDULENE"
                            ? true
                            : false
                        }
                        className={classes.promoTextBoxBlue}
                        disabled={headings == "UPDATE_PROMO" ? true : false}
                        value={MAXWEEKS.promoValue ? MAXWEEKS.promoValue : ""}
                        minlength={10}
                        maxlength={10}
                        inputlabel="Max Weeks"
                        OnChange={(e) => {
                          if (
                            numberOnlyRegex.test(e.target.value) ||
                            e.target.value == ""
                          ) {
                            setMAXWEEKS({
                              ...MAXWEEKS,
                              promoKey: "MAX_WEEKS",
                              promoValue: e.target.value,
                            });
                          }
                        }}
                      ></RACTextbox>
                    </Grid>
                  ) : (
                    (MAXWEEKS.promoValue = "")
                  )}

                  {postObj.promoType == "HLFOFF_NTR" ? (
                    <Grid item md={4} style={{ paddingBottom: "18px" }}>
                      <RACTextbox
                        required={
                          headings == "CLONE" || headings == "NEW_SCHEDULENE"
                            ? true
                            : false
                        }
                        className={classes.promoTextBoxBlue}
                        disabled={headings == "UPDATE_PROMO" ? true : false}
                        value={MAXDAYS.promoValue ? MAXDAYS.promoValue : ""}
                        minlength={10}
                        maxlength={10}
                        inputlabel="Max Days"
                        OnChange={(e) => {
                          if (
                            numberOnlyRegex.test(e.target.value) ||
                            e.target.value == ""
                          ) {
                            setMAXDAYS({
                              ...MAXDAYS,
                              promoKey: "MAX_DAYS",
                              promoValue: e.target.value,
                            });
                          }
                        }}
                      ></RACTextbox>
                    </Grid>
                  ) : (
                    (MAXDAYS.promoValue = "")
                  )}
                  {postObj.promoType == "PIFEPOIPF" ? (
                    <Grid item md={4} style={{ paddingBottom: "18px" }}>
                      <RACTextbox
                        required={
                          headings == "CLONE" || headings == "NEW_SCHEDULENE"
                            ? true
                            : false
                        }
                        className={classes.promoTextBoxBlue}
                        disabled={headings == "UPDATE_PROMO" ? true : false}
                        value={
                          MAXDAYSOTHER.promoValue ? MAXDAYSOTHER.promoValue : ""
                        }
                        minlength={10}
                        maxlength={10}
                        inputlabel="Max Days Other"
                        OnChange={(e) => {
                          if (
                            numberOnlyRegex.test(e.target.value) ||
                            e.target.value == ""
                          ) {
                            setMAXDAYSOTHER({
                              ...MAXDAYSOTHER,
                              promoKey: "MAX_DAYS_OTHER",
                              promoValue: e.target.value,
                            });
                          }
                        }}
                      ></RACTextbox>
                    </Grid>
                  ) : (
                    (MAXDAYSOTHER.promoValue = "")
                  )}

                  {postObj.promoType == "PIFEPOIPF" ? (
                    <Grid item md={4} style={{ paddingBottom: "18px" }}>
                      <RACTextbox
                        required={
                          headings == "CLONE" || headings == "NEW_SCHEDULENE"
                            ? true
                            : false
                        }
                        className={classes.promoTextBoxBlue}
                        disabled={headings == "UPDATE_PROMO" ? true : false}
                        value={
                          MAXDAYSMONTHLY.promoValue
                            ? MAXDAYSMONTHLY.promoValue
                            : ""
                        }
                        minlength={10}
                        maxlength={10}
                        inputlabel="Max Days Monthly "
                        OnChange={(e) => {
                          if (
                            numberOnlyRegex.test(e.target.value) ||
                            e.target.value == ""
                          ) {
                            setMAXDAYSMONTHLY({
                              ...MAXDAYSMONTHLY,
                              promoKey: "MAX_DAYS_MONTHLY",
                              promoValue: e.target.value,
                            });
                          }
                        }}
                      ></RACTextbox>
                    </Grid>
                  ) : (
                    (MAXDAYSMONTHLY.promoValue = "")
                  )}

                  <Grid item md={4} style={{ paddingBottom: "18px" }}>
                    <RACTextbox
                      required={
                        (headings == "CLONE" || headings == "NEW_SCHEDULENE") &&
                        maxDaysAllowedArray.includes(postObj.promoType)
                          ? true
                          : false
                      }
                      className={classes.promoTextBoxBlue}
                      disabled={maxWeeksAllowedFlag}
                      value={
                        postObj.promoConfig?.maxDaysAllowed != ""
                          ? postObj.promoConfig?.maxDaysAllowed
                          : ""
                      }
                      minlength={10}
                      maxlength={10}
                      inputlabel="Max Days Allowed"
                      OnChange={(e) => {
                        if (
                          numberOnlyRegex.test(e.target.value) ||
                          e.target.value == ""
                        ) {
                          setPostObj((pre: any) => ({
                            ...pre,
                            promoConfig: {
                              ...pre.promoConfig,
                              maxDaysAllowed: e.target.value,
                            },
                          }));
                        }
                      }}
                    ></RACTextbox>
                  </Grid>
                  <Grid item md={4} style={{ paddingBottom: "18px" }}>
                    <RACTextbox
                      required={
                        (headings == "CLONE" || headings == "NEW_SCHEDULENE") &&
                        postObj.promoType == "PAYXGETY"
                          ? true
                          : false
                      }
                      className={classes.promoTextBoxBlue}
                      disabled={
                        headings == "UPDATE_PROMO"
                          ? true
                          : postObj.promoType == "PAYNFSTY+"
                          ? true
                          : freeTimeWeeksAllowedFlag
                      }
                      value={
                        postObj.promoConfig?.freeTimeDaysAllowed != ""
                          ? postObj.promoConfig?.freeTimeDaysAllowed
                          : ""
                      }
                      minlength={10}
                      maxlength={10}
                      inputlabel="Freetime Days Allowed"
                      OnChange={(e) => {
                        if (
                          numberOnlyRegex.test(e.target.value) ||
                          e.target.value == ""
                        ) {
                          setPostObj((pre: any) => ({
                            ...pre,
                            promoConfig: {
                              ...pre.promoConfig,
                              freeTimeDaysAllowed: e.target.value,
                            },
                          }));
                        }
                      }}
                    ></RACTextbox>
                  </Grid>
                  <Grid item md={4} style={{ paddingBottom: "18px" }}>
                    <RACTextbox
                      required={
                        (headings == "CLONE" || headings == "NEW_SCHEDULENE") &&
                        (postObj.promoType == "AED" ||
                          postObj.promoType === "PAYXGETY")
                          ? true
                          : false
                      }
                      className={classes.promoTextBoxBlue}
                      disabled={
                        headings == "UPDATE_PROMO"
                          ? true
                          : postObj.promoType == "PAYNFSTY+"
                          ? true
                          : freeDayFlag
                      }
                      value={
                        postObj.promoConfig?.numberOfFreeDays != ""
                          ? postObj.promoConfig?.numberOfFreeDays
                          : ""
                      }
                      minlength={10}
                      maxlength={10}
                      inputlabel="#of Free Days"
                      OnChange={(e) => {
                        if (
                          numberOnlyRegex.test(e.target.value) ||
                          e.target.value == ""
                        ) {
                          setPostObj((pre: any) => ({
                            ...pre,
                            promoConfig: {
                              ...pre.promoConfig,
                              numberOfFreeDays: e.target.value,
                            },
                          }));
                        }
                      }}
                    ></RACTextbox>
                  </Grid>
                  <Grid item md={4} style={{ paddingBottom: "18px" }}>
                    <RACTextbox
                      className={classes.promoTextBoxBlue}
                      // inputLabelClassname={Class.pb2}
                      disabled={
                        headings == "UPDATE_PROMO"
                          ? true
                          : postObj.promoType == "PIFEPOMNM" ||
                            postObj.promoType == "PAYNFSTY+"
                          ? true
                          : referralPromoFlag
                      }
                      value={
                        postObj.promoConfig?.referralPromoFreeDays != ""
                          ? postObj.promoConfig?.referralPromoFreeDays
                          : ""
                      }
                      minlength={10}
                      maxlength={10}
                      inputlabel="#Referral Promo Free Days"
                      OnChange={(e) => {
                        if (
                          numberOnlyRegex.test(e.target.value) ||
                          e.target.value == ""
                        ) {
                          setPostObj((pre: any) => ({
                            ...pre,
                            promoConfig: {
                              ...pre.promoConfig,
                              referralPromoFreeDays: e.target.value,
                            },
                          }));
                        }
                      }}
                    ></RACTextbox>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    style={{ paddingBottom: "18px" }}
                    className={
                      bedCrumbs == "UPDATE_PROMO" || startDateFlag == true
                        ? classes.cusStyleDisableColor
                        : classes.datePickerStyles
                    }
                  >
                    {postObj.startDate ? (
                      <RACDatePicker
                        required={
                          headings == "NEW_SCHEDULENE" || headings == "CLONE"
                            ? true
                            : false
                        }
                        disabled={startDateFlag}
                        inputProps={{
                          min: moment().format("YYYY-MM-DD"),
                          max: moment(postObj.endDate).format("YYYY-MM-DD"),
                        }}
                        value={postObj.startDate ? postObj.startDate : ""}
                        onChange={(e: any) => {
                          const sDate = moment(e, "YYYY-MM-DD");
                          const eDate = moment(postObj.endDate, "YYYY-MM-DD");
                          setPostObj({
                            ...postObj,
                            startDate: e,
                            endDate: "",
                          });
                        }}
                        label="Start Date"
                        onKeyDown={hanleKeyBoardEvents}
                      />
                    ) : (
                      <RACDatePicker
                        required={
                          headings == "NEW_SCHEDULENE" || headings == "CLONE"
                            ? true
                            : false
                        }
                        disabled={startDateFlag}
                        inputProps={{ min: moment().format("YYYY-MM-DD") }}
                        value={postObj.startDate ? postObj.startDate : ""}
                        onChange={(e: any) => {
                          setPostObj({
                            ...postObj,
                            startDate: e,
                          });
                        }}
                        label="Start Date"
                        onKeyDown={hanleKeyBoardEvents}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    md={4}
                    className={classes.datePickerStyles}
                    style={{ paddingBottom: "18px" }}
                  >
                    <RACDatePicker
                      required={
                        headings == "NEW_SCHEDULENE" || headings == "CLONE"
                          ? true
                          : false
                      }
                      inputProps={{ min: moment().format("YYYY-MM-DD") }}
                      value={
                        /* String(new Date(updtPopupData.startDate)) */ moment(
                          postObj.endDate
                        ).format("YYYY-MM-DD")
                      }
                      onChange={(e: any) => {
                        const date1 = moment(e, "YYYY-MM-DD");
                        const date2 = moment(postObj.startDate, "YYYY-MM-DD");
                        if (date2.isAfter(date1) || date1.isBefore(date2)) {
                          setPostObj({
                            ...postObj,
                            startDate: e,
                            endDate: "",
                          });
                        } else {
                          setPostObj({
                            ...postObj,
                            endDate: e,
                          });
                        }
                      }}
                      label="End Date"
                      onKeyDown={hanleKeyBoardEvents}
                    />
                  </Grid>
                  <Grid item md={4} style={{ paddingBottom: "18px" }}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontFamily: "OpenSans-bold",
                      }}
                    >
                      Customer Order
                    </Typography>
                    <RACToggle
                      checked={
                        postObj?.promoEnabled?.customerOrderEnabled == "Y"
                      }
                      toggleColor="#2179fe"
                      OnChange={(e: any) => {
                        if (e.target.checked) {
                          setPostObj((pre: any) => ({
                            ...pre,
                            promoEnabled: {
                              ...pre.promoEnabled,
                              customerOrderEnabled: "Y",
                            },
                          }));
                        } else {
                          setPostObj((pre: any) => ({
                            ...pre,
                            promoEnabled: {
                              ...pre.promoEnabled,
                              customerOrderEnabled: "N",
                            },
                          }));
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={4} style={{ paddingBottom: "18px" }}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontFamily: "OpenSans-bold",
                      }}
                    >
                      Ecomm
                    </Typography>
                    <RACToggle
                      checked={postObj?.promoEnabled?.ecommEnabled == "Y"}
                      toggleColor="#2179fe"
                      OnChange={(e: any) => {
                        if (e.target.checked) {
                          setPostObj((pre: any) => ({
                            ...pre,
                            promoEnabled: {
                              ...pre.promoEnabled,
                              ecommEnabled: "Y",
                            },
                          }));
                        } else {
                          setPostObj((pre: any) => ({
                            ...pre,
                            promoEnabled: {
                              ...pre.promoEnabled,
                              ecommEnabled: "N",
                            },
                          }));
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={4} style={{ paddingBottom: "18px" }}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontFamily: "OpenSans-bold",
                      }}
                    >
                      Online Only
                    </Typography>
                    <RACToggle
                      checked={postObj?.promoEnabled?.onlineOnlyEnabled == "Y"}
                      toggleColor="#2179fe"
                      OnChange={(e: any) => {
                        if (e.target.checked) {
                          setPostObj((pre: any) => ({
                            ...pre,
                            promoEnabled: {
                              ...pre.promoEnabled,
                              onlineOnlyEnabled: "Y",
                            },
                          }));
                        } else {
                          setPostObj((pre: any) => ({
                            ...pre,
                            promoEnabled: {
                              ...pre.promoEnabled,
                              onlineOnlyEnabled: "N",
                            },
                          }));
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={4} style={{ paddingBottom: "18px" }}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontFamily: "OpenSans-bold",
                      }}
                    >
                      Promo Text Box
                    </Typography>
                    <RACToggle
                      checked={
                        postObj?.promoEnabled?.promoTextBoxEnabled == "Y"
                      }
                      toggleColor="#2179fe"
                      OnChange={(e: any) => {
                        if (e.target.checked) {
                          setPostObj((pre: any) => ({
                            ...pre,
                            promoEnabled: {
                              ...pre.promoEnabled,
                              promoTextBoxEnabled: "Y",
                            },
                          }));
                        } else {
                          setPostObj((pre: any) => ({
                            ...pre,
                            promoEnabled: {
                              ...pre.promoEnabled,
                              promoTextBoxEnabled: "N",
                            },
                          }));
                        }
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    className={
                      bedCrumbs == "UPDATE_PROMO"
                        ? classes.txtAreaStyleDisableColor
                        : classes.promoTextBoxBlue
                    }
                    style={{ paddingBottom: "18px" }}
                  >
                    <Typography style={{ fontFamily: "OpenSans-bold" }}>
                      Reason for Promotion
                    </Typography>
                    <textarea
                      maxLength={50}
                      value={postObj.promoReason}
                      rows={6}
                      style={{
                        resize: "none",
                        width: "100%",
                        borderRadius: "5px",
                        borderColor: "#e2e2e4",
                        padding: "12px",
                        fontFamily: "inherit",
                      }}
                      disabled={resnForPromoFlag}
                      onChange={(e: any) => {
                        setPostObj({
                          ...postObj,
                          promoReason: e.target.value,
                        });
                      }}
                    ></textarea>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    className={
                      bedCrumbs == "UPDATE_PROMO"
                        ? classes.txtAreaStyleDisableColor
                        : classes.promoTextBoxBlue
                    }
                    style={{ paddingBottom: "18px" }}
                  >
                    <Typography style={{ fontFamily: "OpenSans-bold" }}>
                      Promo Description
                    </Typography>
                    <textarea
                      className={classes.promoTextBoxBlue}
                      maxLength={250}
                      value={postObj.promoDesc}
                      rows={6}
                      style={{
                        resize: "none",
                        width: "100%",
                        borderRadius: "5px",
                        borderColor: "#e2e2e4",
                        padding: "12px",
                        fontFamily: "inherit",
                      }}
                      disabled={promoDescFlag}
                      onChange={(e: any) => {
                        setPostObj({ ...postObj, promoDesc: e.target.value });
                      }}
                    ></textarea>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    className={
                      bedCrumbs == "UPDATE_PROMO"
                        ? classes.txtAreaStyleDisableColor
                        : classes.promoTextBoxBlue
                    }
                    style={{ paddingBottom: "18px" }}
                  >
                    <Typography style={{ fontFamily: "OpenSans-bold" }}>
                      Promo Details
                    </Typography>
                    <textarea
                      className={classes.promoTextBoxBlue}
                      maxLength={1024}
                      value={postObj.promoDetailsText}
                      rows={8}
                      style={{
                        resize: "none",
                        width: "100%",
                        borderRadius: "5px",
                        borderColor: "#e2e2e4",
                        padding: "12px",
                        fontFamily: "inherit",
                      }}
                      disabled={promoDetailsTxtFlag}
                      onChange={(e: any) => {
                        setPostObj({
                          ...postObj,
                          promoDetailsText: e.target.value,
                        });
                      }}
                    ></textarea>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            style={{ overflow: "visible", tableLayout: "fixed" }}
            item
            md={6}
          >
            <Typography
              style={{
                marginBottom: "5px",
                fontFamily: "OpenSans-bold !important",
              }}
              variant="h5"
            >
              Assign Stores
            </Typography>
            <Card style={{ overflow: "visible", borderRadius: "18px" }}>
              <CardContent>
                <Grid container spacing={4}>
                  <Grid className={classes.multiSelectStyles} item md={4}>
                    <Typography style={{ fontFamily: "OpenSans-bold" }}>
                      Organization
                      <span style={{ color: "red", paddingLeft: "3px" }}>
                        {noAssStoresFound == true ||
                        headings == "NEW_SCHEDULENE" ||
                        postObj.assignStores?.hierarchyType != "" ||
                        orgArray?.length != 0
                          ? "*"
                          : ""}
                      </span>
                    </Typography>
                    <MultiSelect
                      hasSelectAll={hasSelectAll}
                      isLoading={filterLoaderFlag}
                      options={organization}
                      value={formOrganizationSelected}
                      className={`${pricing.demo}  ${pricing.promozoneMultiSelect} `}
                      onChange={(e: any) => {
                        setHasSelectAllgetHeirarchyForm(true);
                        setHierarchyValueField(false);
                        setHiererchyArrayForm([]);
                        postObj.assignStores.hierarchyType = "";
                        postObj.assignStores.hierarchyValue = "";
                        setFormOrganizationSelected(e);
                        let arr = e.map((item: any) => {
                          if (item.value == item.value) return item.value;
                        });
                        console.log(arr);
                        setOrgArray(arr);
                        if (arr.length > 0) {
                          if (franchiseeuser == true) {
                            setPostObj((pre: any) => ({
                              ...pre,
                              assignStores: {
                                ...pre.assignStores,
                                companyCode: arr,
                              },
                            }));
                          } else {
                            if (organization.length == arr.length) {
                              setPostObj((pre: any) => ({
                                ...pre,
                                assignStores: {
                                  ...pre.assignStores,
                                  companyCode: [],
                                },
                              }));
                            } else {
                              setPostObj((pre: any) => ({
                                ...pre,
                                assignStores: {
                                  ...pre.assignStores,
                                  companyCode: arr,
                                },
                              }));
                            }
                          }
                        } else {
                          setHierarchyValueField(false);
                          setHiererchyArrayForm([]);
                          postObj.assignStores.hierarchyType = "";
                          postObj.assignStores.hierarchyValue = "";
                        }
                      }}
                      labelledBy={"Select"}
                    />
                  </Grid>
                  <Grid className={classes.dropDownStyles} item md={4}>
                    <RACSelect
                      isDisabled={orgArray.length == 0 ? true : false}
                      inputLabel="Type"
                      required={
                        noAssStoresFound == true ||
                        headings == "NEW_SCHEDULENE" ||
                        postObj.assignStores?.hierarchyType != "" ||
                        orgArray?.length != 0
                          ? true
                          : false
                      }
                      options={typeDropdown}
                      defaultValue={
                        postObj.assignStores?.hierarchyType
                          ? postObj.assignStores?.hierarchyType
                          : ""
                      }
                      onChange={(e) => {
                        if (e.target.value == "") {
                          setHierarchyValueField(false);
                          setHiererchyArrayForm([]);
                        } else {
                          handleType(e, "hierarchyType");
                        }
                        setPostObj((pre: any) => ({
                          ...pre,
                          assignStores: {
                            ...pre.assignStores,
                            hierarchyType: e.target.value,
                          },
                        }));
                        setHasSelectAllgetHeirarchyForm(true);
                        setHierarchyLoader(true);
                        setFormHierarchyValueSelected([]);
                      }}
                    />
                  </Grid>
                  {hierarchyValueField == true ? (
                    <Grid className={classes.multiSelectStyles} item md={4}>
                      <Typography style={{ fontFamily: "OpenSans-bold" }}>
                        {postObj.assignStores?.hierarchyType}
                        <span style={{ color: "red", paddingLeft: "3px" }}>
                          {noAssStoresFound == true ||
                          headings == "NEW_SCHEDULENE" ||
                          postObj.assignStores?.hierarchyType != "" ||
                          orgArray?.length != 0
                            ? "*"
                            : ""}
                        </span>
                      </Typography>
                      <MultiSelect
                        hasSelectAll={hasSelectAllgetHeirarchyForm}
                        options={typeObj}
                        isLoading={hierarchyLoader}
                        value={formHierarchyValueSelected}
                        className={`${pricing.demo}  ${pricing.prcingzoneMultiSelect} `}
                        onChange={(e: any) => {
                          setFormHierarchyValueSelected(e);
                          let arr = e.map((item: any) => {
                            if (item.value == item.value) return item.value;
                          });
                          console.log("arr at assignstore heivalue", arr);

                          arr = arr.includes("") ? [] : arr;
                          setHiererchyArrayForm(arr);
                          if (arr.length > 0) {
                            if (typeObj.length == arr.length) {
                              setPostObj((pre: any) => ({
                                ...pre,
                                assignStores: {
                                  ...pre.assignStores,
                                  hierarchyValue: [],
                                },
                              }));
                            } else {
                              setPostObj((pre: any) => ({
                                ...pre,
                                assignStores: {
                                  ...pre.assignStores,
                                  hierarchyValue: arr,
                                },
                              }));
                            }
                          }
                        }}
                        labelledBy={"Select"}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </CardContent>
            </Card>
            {assStoreGrid == true ? (
              <PromoSchedulerAssociatedStoresGrid />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
